import React from 'react';
import { useAtomValue } from 'jotai';
import { theme } from '@fiverr-private/theme';
import { ContactSellerButton } from '@fiverr-private/send_message';
import {
    isFloatingChatActiveAtom,
    SUPPORTED_ACTIONS,
    useIsUserOnline,
    useMonitoring,
    UserActivationModalWrapper,
} from '@fiverr-private/seller_pages_toolbox';
import { Container } from '@fiverr-private/layout_components';
import { CustomContactSellerModal } from '../ContactSellerModal/CustomContactSellerModal';
import { useContactSellerModalData } from '../../hooks/useContactSellerModalData';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';

const selectSellerSlice = ({
    user: {
        name,
        profileImageUrl,
        profile: { displayName },
    },
    responseTime,
}: AgencySeller) => ({
    username: name,
    profilePhoto: profileImageUrl,
    responseTimeInHrs: responseTime?.inHours,
    displayName,
});

export const MessageAgencyButton = () => {
    const { fireEvents, Providers } = useMonitoring();
    const { username, profilePhoto, responseTimeInHrs, displayName } = useAgencySellerData(selectSellerSlice);
    const isOnline = useIsUserOnline(username);
    const isFloatingChatEnabled = useAtomValue(isFloatingChatActiveAtom);

    const { isUserModalActivated, onCloseUserModal, contactSellerModalProps, showContactSellerModal, onContactClick } =
        useContactSellerModalData({ isAnchorVisible: true });

    const openContactModal = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'clicked on sticky chat option',
            },
            [Providers.BigQuery]: {
                type: 'clicked_on_sticky_chat_option',
                page: {
                    element: {
                        name: 'role_sticky_chat',
                        text: JSON.stringify({
                            'online/offline': isOnline ? 'online' : 'offline',
                            avg_response_time: responseTimeInHrs ? `${responseTimeInHrs}_hour` : undefined,
                        }),
                    },
                },
            },
        });

        onContactClick();
    };

    return (
        <Container
            display={{
                default: 'none',
                lg: 'inlineBlock',
            }}
            position="sticky"
            left={theme.spacing['8']}
            bottom={theme.spacing['8']}
            zIndex="2"
            marginBottom="16"
        >
            <ContactSellerButton
                recipientInfo={{
                    username,
                    profilePhoto,
                    responseTimeInHrs,
                    isOnline,
                    displayName,
                }}
                onClick={openContactModal}
                isAssistantActive={isFloatingChatEnabled}
            />
            <CustomContactSellerModal showModal={showContactSellerModal} modalProps={contactSellerModalProps} />
            <UserActivationModalWrapper
                isOpen={isUserModalActivated}
                onClose={onCloseUserModal}
                action={SUPPORTED_ACTIONS.CONTACT_SELLER}
            />
        </Container>
    );
};
