import React from 'react';
import { Text } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import { PlusIcon } from '@fiverr-private/icons';
import { useBriefContext } from '../../../common/BriefContext';

interface ChipProps {
    title: string;
    onClick: () => void;
}
// TODO: Add to Penta
export const Chip = ({ title, onClick }: ChipProps) => {
    const { isBriefInEditMode } = useBriefContext();

    return (
        <Stack
            width="fit-content"
            cursor={isBriefInEditMode ? undefined : 'pointer'}
            onClick={onClick}
            direction="row"
            gap="2"
            borderColor="grey_500"
            borderStyle="solid"
            borderWidth="sm"
            paddingX="4"
            paddingY="2"
            borderTopLeftRadius="none"
            borderTopRightRadius="3xl"
            borderBottomRightRadius="3xl"
            borderBottomLeftRadius="xl"
        >
            <Text overflow="hidden" size="b_sm">
                <Stack gap="2" direction="row" alignItems="center" justifyContent="flexStart">
                    <PlusIcon color="black" size={12} />
                    <Text size="b_sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="bodyPrimary">
                        {title}
                    </Text>
                </Stack>
            </Text>
        </Stack>
    );
};
