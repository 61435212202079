import React from 'react';
import { translate as t } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { Container, Divider, Stack } from '@fiverr-private/layout_components';
import { containerPaddingXBySmBreakpoint } from '../../../common/containerStylePaddingX.ve.css';
import { responsiveDisplay } from '../../util';
import { ExcludedSectionsList } from '../ExcludedSectionsList';

interface ExcludedSectionSidebarProps {
    title?: string;
}

export const ExcludedSectionsSidebar = ({
    title = t('ai_brief.edit_brief.recommended_sections'),
}: ExcludedSectionSidebarProps) => (
    <Container
        className={containerPaddingXBySmBreakpoint}
        display={responsiveDisplay('flex', true)}
        direction="row"
        gap="8"
        marginRight="8"
    >
        <Stack direction="column" paddingY="8" width={268} gap="3" flexShrink={0} overflowX="visible">
            <Text size="b_xs" color="bodySecondary">
                {title}
            </Text>
            <ExcludedSectionsList />
        </Stack>
        <Divider direction="vertical" height="100%" />
    </Container>
);
