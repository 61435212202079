import React, { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { Center } from '@fiverr-private/layout_components';
import { ChevronDownIcon } from '@fiverr-private/icons';

import { SIZES } from '../../../constants';
import { EVENT_TYPE, bigQuery } from '../../../monitoring/bigQuery';
import { mixpanel } from '../../../monitoring/mixpanel';
import {
    ContactBIEnrichment,
    ContactButtonPosition,
} from '../../../monitoring/bigQuery/createContactButtonEventEnrichment';
import { useFloatingChatTrigger } from '../../../hooks/useFloatingChatTrigger';
import { expandedChevronClassName, gradientBorderStyle } from './styles.ve.css';

export interface ContactTriggerButtonProps extends Pick<ComponentProps<typeof Button>, 'size' | 'className'> {
    source: ContactButtonPosition;
    biEnrichment: ContactBIEnrichment;
    isOptionsShown?: boolean;
    onClick: (isActive: boolean) => void;
}

export const ContactTriggerButton: FC<ContactTriggerButtonProps> = ({
    source,
    biEnrichment,
    size = SIZES.MD,
    className,
    isOptionsShown,
    onClick,
}) => {
    const [isActive, setIsActive] = useState(false);
    const { isFloatingChatEnabled } = useFloatingChatTrigger();

    const isChevronActive = useMemo(() => isActive && isOptionsShown, [isActive, isOptionsShown]);

    useEffect(() => {
        setIsActive(!!isOptionsShown);
    }, [isOptionsShown]);

    const handleOnClick = () => {
        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_CONTACT_US_DROPDOWN, biEnrichment);
        mixpanel.event(`${source} - Clicked contact us dropdown`);

        setIsActive((previousIsActive) => {
            const updatedIsActive = !previousIsActive;
            onClick(updatedIsActive);
            return updatedIsActive;
        });
    };

    return (
        <Button
            onClick={handleOnClick}
            size={size}
            className={classnames(className, { [gradientBorderStyle]: isFloatingChatEnabled })}
        >
            <I18n k="agency_page_perseus.contact_us" />
            <Center className={isChevronActive ? expandedChevronClassName : undefined}>
                <ChevronDownIcon color="currentColor" size={12} />
            </Center>
        </Button>
    );
};
