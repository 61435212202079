import { theme, Types } from '@fiverr-private/theme';
import { Brief } from '../../types';

export const responsiveDisplay = (
    displayType: Types.Display,
    isBelongToBigScreen: boolean
): Types.ResponsiveDisplayType => {
    const display = (shouldShow: boolean): Types.Display => (shouldShow ? displayType : 'none');
    const breakpoint = 'md';
    return {
        default: display(!isBelongToBigScreen),
        [breakpoint]: display(isBelongToBigScreen),
    };
};

export const excludeEmptySections = (brief: Brief): Brief => {
    const updatedSections = [...(brief?.sections || [])].map((section) =>
        section?.content.trim() || section.attachments?.length ? section : { ...section, included: false }
    );
    return { ...brief, sections: updatedSections };
};

export const getNearestOpacity = (value: number) =>
    Object.keys(theme.opacity).reduce((prev, curr) =>
        Math.abs(Number(curr) - value) < Math.abs(Number(prev) - value) ? curr : prev
    ) as keyof typeof theme.opacity;
