import React from 'react';
import { AttachmentIcon } from '@fiverr-private/icons';
import { IconButton } from '@fiverr-private/button';
import { Stack } from '@fiverr-private/layout_components';
import { SectionTypes } from '../../../types';

export const isAttachmentsSection = (section) => section?.type === SectionTypes.attachments;

export const AttachmentsChip = ({ onClick }: { onClick: () => void }) => (
    <Stack>
        <IconButton
            aria-label="Attachments"
            shape="circle"
            intent="secondary"
            variant="outline"
            onClick={onClick}
            size="md"
        >
            <AttachmentIcon />
        </IconButton>
    </Stack>
);
