import React, { useLayoutEffect, useRef } from 'react';
import { isEmpty, isNil } from 'lodash';
import { Divider, Section } from '@fiverr-private/layout_components';
import { Box } from '@fiverr-private/theme';
import { translate } from '@fiverr-private/i18n-react';
import { useSellerAiModels } from '@fiverr-private/seller_pages_toolbox';
import { getContext } from '@fiverr-private/fiverr_context';
import { AgencyAbout } from '../AgencyAbout';
import { AgencyClients } from '../AgencyClients';
import { AgencyGigs } from '../AgencyGigs';
import { AgencyOverview } from '../AgencyOverview';
import { AgencyOverviewDivider } from '../AgencyOverviewDivider';
import { AgencyPortfolioNew } from '../AgencyPortfolioNew';
import { AgencyReviews } from '../AgencyReviews';
import { AgencyTeam } from '../AgencyTeam';
import { ContactSellerModalWrapper } from '../ContactSellerModal';
import { MachineTranslationButton } from '../MachineTranslationButton';
import { StickyFooter } from '../StickyFooter';
import { StickyHeader } from '../StickyHeader';
import { bigQuery } from '../../monitoring/bigQuery';
import { usePortfolio } from '../../hooks/usePortfolio/usePortfolio';
import { VacationMessageBanner } from '../VacationMessageBanner';
import { LazyIndustries } from '../AgencyIndustries/AgencyIndustires.lazy';
import { LazyServices } from '../AgencyServices/AgencyServices.lazy';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { Testimonials } from '../Testimonials/Testimonials';
import { AiModelsCarouselWrapper } from '../AiModelComponents/AiModelsCarouselWrapper';
import { MessageAgencyButton } from '../MessageAgencyButton';

export const AgencyPage = () => {
    const stickyHeaderThresholdRef = useRef<HTMLDivElement>(null);
    const seller = useAgencySellerData((seller: AgencySeller) => ({
        id: seller.user.id,
        sellerAIModels: seller.sellerAIModels,
    }));
    const sellerAIModels = useSellerAiModels();

    const { isMobile } = getContext();

    const { showPortfolio } = usePortfolio();
    const showAiDeliveryCarousel = !isEmpty(sellerAIModels) && !isNil(sellerAIModels);

    useLayoutEffect(() => {
        bigQuery.initBaseData({
            seller,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StickyHeader observeHideRef={stickyHeaderThresholdRef} />
            <StickyFooter observeHideRef={stickyHeaderThresholdRef} />
            <Section>
                <ContactSellerModalWrapper />
                <AgencyOverview />
                <AgencyOverviewDivider />
                <div ref={stickyHeaderThresholdRef} />
                <VacationMessageBanner />
                <Box marginBottom="5">
                    <MachineTranslationButton />
                </Box>
                <Box marginBottom="12">
                    <AgencyAbout />
                </Box>
                <Box marginBottom={showPortfolio ? '12' : '10'}>
                    <AgencyTeam />
                </Box>
                <Box marginBottom={showPortfolio ? '10' : '0'}>
                    <AgencyPortfolioNew />
                </Box>
                <Box marginBottom="10">
                    <LazyIndustries />
                </Box>
                <Box marginBottom="10">
                    <AgencyClients />
                </Box>
                <Box marginY="12">
                    <Divider direction="horizontal" />
                </Box>
                <LazyServices />
                <Box marginY="12">
                    <Divider direction="horizontal" />
                </Box>
                <AgencyGigs />
                {showAiDeliveryCarousel && (
                    <Box marginTop="12">
                        <AiModelsCarouselWrapper
                            title={translate('agency_page_perseus.ai_models.carousel.title')}
                            description={translate('agency_page_perseus.ai_models.carousel.description')}
                        />
                    </Box>
                )}
                <Box marginY="12">
                    <Divider direction="horizontal" />
                </Box>
                <AgencyReviews />
                <Box marginTop="12">
                    <Testimonials />
                </Box>
            </Section>
            {!isMobile && <MessageAgencyButton />}
        </>
    );
};
