import React from 'react';
import { Box } from '@fiverr-private/theme';

import { avatarImageClass, avatarImageMinimizedClass } from './styles.ve.css';

interface AgencyAvatarProps {
    avatarUrl: string;
    isMinimized?: boolean;
}

export const AgencyAvatar = ({ avatarUrl, isMinimized = false }: AgencyAvatarProps) => (
    <Box position="relative">
        <img src={avatarUrl} alt="Avatar" className={isMinimized ? avatarImageMinimizedClass : avatarImageClass} />
    </Box>
);
